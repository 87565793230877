'use strict';

jQuery(function ($) {
	// Au scroll faire apparaitre ou disparaitre le bouton de retour en haut de page
	$(window).on('scroll', function () {
		var $back = $('.back-to-top');
		var duration = 600;
		var posScroll = $(document).scrollTop();

		if (posScroll >= 550) {
			$back.fadeIn(duration);
		} else {
			$back.fadeOut(duration);
		}
	});

	$('.back-to-top').on('click', function (event) {
		event.preventDefault();
		$('html, body').animate({ scrollTop: 0 }, 500);
	});

	// Accordion
	$('.accordion-toggle.collapse.in').removeClass('in').css('height', '');
	$('.accordion-heading').on('click', function (e) {
		e.preventDefault();
		e.stopPropagation();
		var $showed = $('.accordion-body.show');
		$(this).parent().addClass('actif').siblings().removeClass('actif');
		$(this).next('.accordion-body').toggleClass('show').css('height', '');
		if ($(this).parent().index() !== $showed.parent().index()) {
			$showed.toggleClass('show').css('height', '');
			$(this).parent().addClass('actif');
		} else {
			$(this).parent().removeClass('actif');
		}
	});
});

